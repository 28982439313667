import _, { isEmpty, isNumber, isString, isNaN } from "lodash";
import moment from "moment-timezone";
import numeral from "numeral";
import queryString from "query-string";
import { put } from "redux-saga/effects";

import { AuthenticatedUser } from "../store/auth";
import { LocaleWithCountry } from "../store/defaults";
import { enqueueSnackbar } from "../store/notifications";
import {
  LocaleSettingsProps,
  Organisation,
  OrganisationMembers,
  SocialMediaLink,
} from "../store/organisation";
import {
  AgentOption,
  FlattenOrganisationsMember,
  Property,
} from "../store/properties";
import { Template, TemplateGalleryTab } from "../store/socials";
import { DEFAULT_LOCALE_SETTINGS } from "./defaultLocaleSettings";
import { deriveAgentOption } from "./property.helper";
import { Frequencies } from "../store/subscriptions";
import { AddressObjectInterface } from '../components/inputs/AddressSearch';

export const arrayOfDateFormat = [
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "YYYY-MM-DD",
  "DD/MM/YYYY",
  "YYYY/MM/DD",
  "DD MMMM YYYY",
  "YYYY MMMM DD",
];
export const isValidUrl = (url: string): boolean => {
  var regex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  return regex.test(url);
};

export const isMemberOfOrganisation = (
  user: any,
  organisation: Organisation
) => {
  let isOrganisationMember = false;

  if (!_.isEmpty(user) && !_.isEmpty(organisation)) {
    const userId = _.get(user, "id", _.get(user, "_id", ""));
    if (_.isEmpty(userId)) {
      return false;
    }
    const matchedUser = organisation.members!.find(
      (currentMember) => _.get(currentMember, "user._id", "") === userId
    );
    if (matchedUser) {
      isOrganisationMember = true;
    }
  }
  return isOrganisationMember;
};

export const isOwnerOrAdmin = (user: any, organisation: Organisation) => {
  const userRoles = _.get(user, "roles", []);

  if (!_.isEmpty(user) && !_.isEmpty(organisation)) {
    const userId = _.get(user, "id", _.get(user, "_id", ""));
    if (_.isEmpty(userId)) {
      return false;
    }
    const matchedUser = organisation.members!.find(
      (currentMember) => _.get(currentMember, "user._id", "") === userId
    );

    if (userRoles.includes("flow-admin")) {
      return true;
    } else if (matchedUser && _.includes(matchedUser.role, "Owner")) {
      return true;
    } else if (matchedUser && _.includes(matchedUser.role, "Admin")) {
      return true;
    }
  }
  return false;
};

export const flattenMembers = (
  members: OrganisationMembers[]
): AgentOption[] => {
  const options = _.uniqBy(members, "user._id").filter((mem: any) => mem.user);
  return options.map((member: OrganisationMembers) => {
    const { user } = member;

    return deriveAgentOption(user);
  });
};

export const flattenOrganisations = (organisation: Organisation) => {
  const organisations = [{ label: organisation.name, value: organisation._id }];
  const subOrganisations = _.get(organisation, "subOrganisations", []);
  subOrganisations.forEach((subOrganisation) => {
    organisations.push({
      label: subOrganisation.name,
      value: subOrganisation._id,
    });
  });
  return organisations;
};

export const flattenOrganisationsMembers = (
  organisation: Organisation
): FlattenOrganisationsMember => {
  const { _id, members = [], subOrganisations = [] } = organisation;
  const subOrganisationsMembers: FlattenOrganisationsMember = {
    [_id]: flattenMembers(members),
  };

  subOrganisations.reduce((subOrganisationsMember, subOrganisation) => {
    const { _id: subOrgId, members: subOrgMembers = [] } = subOrganisation;
    subOrganisationsMember[subOrgId] = flattenMembers(subOrgMembers);
    return subOrganisationsMember;
  }, subOrganisationsMembers);

  return subOrganisationsMembers;
};

export const isUserOrganisationAdmin = (
  user: any,
  organisation: Organisation
): boolean => {
  let isOrganisationAdmin = false;

  if (!_.isEmpty(user) && !_.isEmpty(organisation)) {
    const userId = _.get(user, "id", _.get(user, "_id", ""));
    if (_.isEmpty(userId)) {
      return false;
    }
    const matchedUser = organisation.members!.find(
      (currentMember) => _.get(currentMember, "user._id", "") === userId
    );
    if (matchedUser) {
      isOrganisationAdmin = _.includes(matchedUser.role, "Admin");
    }
  }
  return isOrganisationAdmin;
};

export const isUserFlowAdmin = (user: any): boolean => {
  if (_.isEmpty(user)) return false;
  const userRoles = _.get(user, "roles", []);
  return userRoles.includes("flow-admin");
};

export const isUserOrganisationOwner = (
  user: any,
  organisation: Organisation
): boolean => {
  let isOrganisationOwner = false;
  if (_.isEmpty(user) || _.isEmpty(organisation)) {
    return false;
  }
  const userId = _.get(user, "id", _.get(user, "_id", ""));
  if (_.isEmpty(userId)) {
    return false;
  }
  const matchedUser = organisation.members!.find(
    (currentMember) => _.get(currentMember, "user._id", "") === userId
  );
  if (matchedUser) {
    isOrganisationOwner = _.includes(matchedUser.role, "Owner");
  }
  return userId === _.get(organisation, "owner") || isOrganisationOwner;
};

export const formatName = (name: string): string => {
  if (!name) {
    return name;
  }
  return _.kebabCase(String(name).toLowerCase());
};

export const getAgencyProfileUrl = (organisation: any): string => {
  const organisationSlug = organisation
    ? _.get(organisation, "slug", formatName(organisation.name))
    : "";
  return `${process.env.REACT_APP_PARENT_URL}/agency/${organisationSlug}`;
};

export const getAgentProfileUrl = (organisation: any, user: any): string => {
  const organisationSlug = organisation
    ? _.get(organisation, "slug", formatName(organisation.name))
    : "";
  const userFullName = user
    ? `${_.get(user, "firstName", "")} ${_.get(user, "lastName", "")}`
    : "";
  const userId = user ? _.get(user, "_id", _.get(user, "id", "")) : "";
  return `${
    process.env.REACT_APP_PARENT_URL
  }/agent/${organisationSlug}/${formatName(userFullName)}/${userId}`;
};

export const getAgentPreviewUrl = (organisation: any, user: any) => {
  const linkType = _.get(organisation, "settings.linkTypes.listing");
  if (linkType === "Flow portal link") {
    return getAgentProfileUrl(organisation, user);
  }

  return user.externalProfileUrl;
};

export const cleanAddress = (address: string): string => {
  if (_.isEmpty(address)) return "";
  const comaStr = ", ";
  const _address = _.startsWith(address, comaStr)
    ? _.trimStart(address, comaStr)
    : address;
  return _address.replace(", undefined", "");
};

export const cleanSocialMediaLinks = (
  socialMediaLinks: SocialMediaLink[]
): SocialMediaLink[] =>
  socialMediaLinks
    ? socialMediaLinks.filter(
        (socialMediaLink: SocialMediaLink) =>
          !_.isEmpty(_.get(socialMediaLink, "link", "")) &&
          isValidUrl(_.get(socialMediaLink, "link", "")) &&
          !_.isEmpty(_.get(socialMediaLink, "type", ""))
      )
    : [];

export const queryFromObject = (keyValue: object) => {
  let query: string = "";
  if (keyValue) {
    for (const [key, value] of Object.entries(keyValue)) {
      query += `&${key}=${value}`;
    }
  }

  return query;
};
export const openPropertyPreview = (link: string, token: string) => {
  const url = token ? `${link}?preview_token=${token}` : link;
  window.open(url, "_blank");
};

export const mergeOrgMember = (org: Organisation, user: AuthenticatedUser) => {
  const findings: any =
    org.members && org.members.find((m) => m.user && m.user._id === user.id);
  if (findings) {
    const index = org.members.indexOf(findings);
    const member = { ...findings, user };
    org.members.splice(index, 0, member);
    return org;
  } else {
    return org;
  }
};

export const getDateString = (dateObj: Date, value: string | null) => {
  const dateMoment = value ? moment(value, "DD/MM/YYYY") : moment(dateObj);
  let dateString;
  if (dateMoment.isValid()) {
    dateString = dateMoment
      .utcOffset(0, true) // convert to UTC/GMT without changing the date-time values
      .startOf("d") // strip off time components (hours = 0, minutes = 0, seconds = 0, milliseconds = 000)
      .toISOString(true); // get ISO string without changing the date-time values
  } else {
    dateString = dateObj.toISOString();
  }

  return dateString;
};
export const priceFormat = (price: string | number, currencySymbol: string) => {
  return `${currencySymbol}${numeral(price).format("0,0")}`;
};

export const formatCurrency = (currency: string, currencySymbol: string) => {
  return currency.replace(/[a-zA-Z]/g, currencySymbol);
};
export const getMinMaxValue = (value: string, type: string) => {
  const isPrice = type === "price";
  const findings: any =
    value && (isPrice ? value.replace(/R/g, " ").split("-") : value.split("-"));
  const minKeyName = isPrice ? "minPrice" : "min";
  const maxKeyName = isPrice ? "maxPrice" : "max";
  if (_.isEmpty(findings)) {
    return {
      [minKeyName]: undefined,
      [maxKeyName]: undefined,
    };
  }

  const rawValue = findings.map((value: string) => numeral(value).value());

  if (!rawValue[0] && !rawValue[1]) {
    return {
      [minKeyName]: undefined,
      [maxKeyName]: undefined,
    };
  }

  if (!rawValue[0] && rawValue[1]) {
    return {
      [minKeyName]: rawValue[1],
      [maxKeyName]: undefined,
    };
  }

  if (rawValue[0] && !rawValue[1]) {
    return {
      [minKeyName]: rawValue[0],
      [maxKeyName]: undefined,
    };
  }

  if (rawValue[0] && rawValue[1]) {
    return {
      [minKeyName]: rawValue[0],
      [maxKeyName]: rawValue[1],
    };
  }

  return {
    [minKeyName]: undefined,
    [maxKeyName]: undefined,
  };
};

export const getCoverImage = (property: Property) => {
  const findCover = property.images.find(
    (image: any) => image.type === "cover"
  );
  return _.get(
    findCover,
    "media.sizes.thumbnail",
    _.get(
      findCover,
      "media.url",
      _.get(
        property,
        "images[0].media.sizes.thumbnail",
        _.get(property, "images[0].media.url")
      )
    )
  );
};

export const getFirstTemplate = (
  tabs: TemplateGalleryTab[],
  keyName: string
): Template | null => {
  //loop through all the templateTypesArray and find the first template;
  const campaignTab = tabs.find((tab) => tab.label === keyName);
  if (_.isEmpty(campaignTab)) {
    return null;
  }
  let templates: Template[] = [];
  const hasTemplates = !_.isEmpty(_.get(campaignTab, "templates"));
  if (hasTemplates) {
    for (let [key, value] of Object.entries(campaignTab!.templates!).sort(
      (a, b) => a[0].localeCompare(b[0])
    )) {
      if (key && !_.isEmpty(value) && _.isArray(value)) {
        templates = templates.concat(value);
      }
    }
  }

  return !_.isEmpty(templates) ? templates[0] : null;
};

export const formatDate = (
  date: Date | string | undefined,
  format?: string,
  locale?: string
): string | undefined => {
  if (!date) {
    return undefined;
  }

  const formattedDate = moment(date, arrayOfDateFormat, locale).format(format);
  if (!moment(formattedDate).isValid()) {
    return undefined;
  }
  return formattedDate;
};

export const mapDateTimeToString = (data: any) => {
  const startDate = _.get(data, "auctionStartDate", undefined);
  const endDate = _.get(data, "auctionEndDate", undefined);

  if (startDate) {
    const auctionStartDate = getCustomDate(startDate);
    Object.assign(data, { auctionStartDate });
  }

  if (endDate) {
    const auctionEndDate = getCustomDate(endDate);
    Object.assign(data, { auctionEndDate });
  }

  return data;
};

const getCustomDate = (date: Date | string): string => {
  const dateObject = moment(date, arrayOfDateFormat);

  /**  get('month') 0 - 11
   * reference:  https://momentjs.com/docs/#/get-set/get/
   **/
  return `${dateObject.get("year")}-${
    dateObject.get("month") + 1
  }-${dateObject.get("date")}`;
};

export const formatTime = (
  date: string,
  country: string,
  defaultValue = ""
) => {
  const zone = moment.tz.zonesForCountry(country);
  return moment(date, "HH:mm").isValid()
    ? moment.tz(date, "HH:mm", zone[0]).format()
    : defaultValue;
};

export const bytesToSize = (bytes: number): string => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};

export const getCountryCodeByContryName = (
  countryName: string,
  locales: LocaleSettingsProps[]
) => {
  const derivedLocale: LocaleSettingsProps[] = locales.filter((loc) =>
    _.isEqual(loc.country.description, countryName)
  );
  if (!_.isEmpty(derivedLocale)) {
    return derivedLocale[0].country.code;
  }
  return "za"; //default
};

export const getCountryCodesArray = (
  locales: LocaleWithCountry[]
): string[] => {
  if (_.isEmpty(locales)) {
    return ["za"];
  }
  return _.uniq(locales.map((loc) => _.get(loc, "country.code", "za")));
};

export const alertErrors = (error: any) => {
  const errors = _.get(error, "errors", [{ msg: error }]);
  return errors.map((err: any) =>
    put(
      enqueueSnackbar({
        message: err.msg,
        options: {
          variant: "error",
        },
      })
    )
  );
};

export const calculateEndDate = (
  initialEndDate: any,
  numberOfDays: number, //TODO : change to number of days
  isEdit = false
) => {
  if (!initialEndDate) {
    return initialEndDate;
  }
  const today = new Date();
  const endDate = new Date(initialEndDate);
  const totalDays = numberOfDays; //TODO : pass days here

  const endDateMoment = moment(endDate);

  if (isEdit) {
    const todayMoment = moment(today);
    const diff = endDateMoment.diff(todayMoment, "days");
    const isDatePast = diff <= 0;

    if (isDatePast) {
      return todayMoment.add(totalDays, "day").toDate();
    }
  }
  return endDateMoment.add(totalDays, "day").toDate();
};

/**
 * Calculate credits used
 * @param credits
 * @param price
 * @param isRecurringCheckout
 */
export const calculateCreditsUsed = (
  credits: number,
  price: number,
  proRata: number = 0,
  isRecurringCheckout: boolean = false,
  isStripe: boolean = false,
  currencyCode = DEFAULT_LOCALE_SETTINGS.currency.code,
  minimumStripeCheckoutAmount: { [key: string]: number } = {}
) => {
  const minimumAllowedBalance =
    minimumStripeCheckoutAmount[currencyCode.toUpperCase()] || 0;

  let creditsUsed = 0,
    balance = price;
  // todo calculate credit for flow brand
  if (!isRecurringCheckout) {
    if (credits > price) {
      creditsUsed = price;
      balance = 0;
    }

    if ((credits > 0 && credits < price) || price === credits) {
      creditsUsed = credits;
      balance = price - credits;
    }
  }

  if (!isStripe) {
    const remainingCredit = credits - creditsUsed;
    if (remainingCredit > 0 && isNumber(proRata) && proRata > 0) {
      if (remainingCredit > proRata) creditsUsed += proRata;
      else if (remainingCredit <= proRata) creditsUsed += remainingCredit;
    }
  } else if (balance > 0 && balance < minimumAllowedBalance) {
    creditsUsed = creditsUsed - (minimumAllowedBalance - balance);
  }

  return creditsUsed;
};

export const getBillingFrequency = (
  isRecurring: boolean,
  frequency: string,
  durationInDays?: number
) => {
  if (isRecurring || durationInDays === undefined)
    return frequency === Frequencies.Monthly ? "/ month" : "/ year";
  // TODO: make use of enums
  else return `/ ${durationInDays} days`;
};

export const redirectMessage = () => {
  return "We’re redirecting you to our trusted payments provider…";
};

// encodedURI should be a string from encodedURI() function
export const getQueryParams = (encodedURI: string) => {
  const paramObjects = queryString.parse(decodeURI(encodedURI));
  const queryObject: any = {};
  if (paramObjects) {
    Object.entries(paramObjects).forEach(([key, value]) => {
      const mappedValue = Array.isArray(value) ? value[0] : value;
      // get first value in an array or return the whole value
      queryObject[key] = mappedValue;
    });

    return queryObject;
  }
  return queryObject;
};

export const createQueryParams = (ObjectParams: any) => {
  let query: string = "";
  const getDelimiter = (q: string) => (_.isEmpty(q) ? "?" : "&");

  if (typeof ObjectParams === "object") {
    Object.entries(ObjectParams).forEach(([key, value]) => {
      query += `${getDelimiter(query)}${key}=${value}`;
    });
  }

  return query;
};

export const getFeeCommisionWarningMessage = (isFlowAdmin: boolean) => {
  return isFlowAdmin
    ? "Flow Commission % is not configured for the selected organisation"
    : "Organisation setting is not complete. Contact Admin.";
};

export const getOrCreatePropAtPath = <T = any, R = any>(
  sourceObj: T,
  path: string | string[]
): R => {
  const keys: string[] = typeof path === "string" ? path.split(".") : path;

  const targetObj = keys.reduce<any>((prop, key) => {
    return (prop[key] = prop[key] || {});
  }, sourceObj);

  return targetObj;
};

/**
 * Check if country uses a different locality
 * @param countryCode string
 */
export const mapAddressWithDifferentLocality = (
  countryCode?: string
): boolean => {
  return ["au"].includes(String(countryCode).toLowerCase());
};

export const getPrivileges = (user: AuthenticatedUser, org: Organisation) => {
  const isAdmin =
    isUserFlowAdmin(user) ||
    isUserOrganisationOwner(user, org) ||
    isUserOrganisationAdmin(user, org);
  const isAllowed = user && isMemberOfOrganisation(user, org);
  return {
    isAdmin,
    isAllowed,
  };
};

export const isNumeric = (strOrNum: string | number): boolean => {
  let num = strOrNum;
  if (isString(strOrNum) && !isEmpty(strOrNum)) num = Number(strOrNum);

  return isNumber(num) && !isNaN(num);
};

export const getInitials = (firstName: string, lastName: string): string => {
  const initials = `${
    !isEmpty(firstName) ? firstName.charAt(0).toUpperCase() : firstName
  }  ${!isEmpty(lastName) ? lastName.charAt(0).toUpperCase() : lastName}`;
  return initials;
};

export const deriveAreaNameFromAddressResult = (addressSearchResult: AddressObjectInterface): string => {
  return _.get(addressSearchResult, 'address.suburb')
    || _.get(addressSearchResult, 'address.city')
    || _.get(addressSearchResult, 'formattedAddress')
    || _.get(addressSearchResult, 'address.province', '');
};
